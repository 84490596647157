<template>
  <div class="count--assign" v-if="!loading.first">
    <div v-show="!loading.assignList">
      <b-tabs nav-class="count--assign-nav" active-nav-item-class="text-default" >
        <b-tab active v-if="permission.teamLeader" @click="selectTab('NOT_ASSIGNMENT')">
          <template #title>
            Por Asignar <b-badge pill variant="danger" class="count--assign-badge">{{countList.notAssign}}</b-badge>
          </template>
          <b-card body-class="pt-0">
            <list-ticket-un-assign
              :permission="permission"
              :listAssign="dataTab.listUnassign"
              :listUsers="dataTab.listUsers"
              :pagination="pagination"
              @updateList="updateListTicket"
              @changePagination="changePagination"
              @changeLimit="changeLimit"
            >
            </list-ticket-un-assign>
          </b-card>
        </b-tab>
        <b-tab @click="selectTab('ASSIGNMENT')">
          <template #title>
            Por Hacer <b-badge pill variant="warning" class="count--assign-badge">{{countList.assign}}</b-badge>
          </template>
          <b-card body-class="pt-0">
            <list-ticket-assign
              :permission="permission"
              :listAssign="dataTab.listAssign"
              :listUsers="dataTab.listUsers"
              :pagination="pagination"
              @updateList="updateListTicket"
              @changePagination="changePagination"
              @changeLimit="changeLimit"></list-ticket-assign>
          </b-card>
        </b-tab>
        <b-tab @click="selectTab('COMPLETED')">
          <template #title>
            Completadas <b-badge pill variant="success" class="count--assign-badge">{{countList.completed}}</b-badge>
          </template>
          <b-card body-class="pt-0">
            <list-ticket-completed
              :permission="permission"
              :listAssign="dataTab.listCompleted"
              :pagination="pagination"
              @changePagination="changePagination"
              @changeLimit="changeLimit"></list-ticket-completed>
          </b-card>
        </b-tab>
      </b-tabs>
    </div>
    <div class="table-render-skeleton" v-show="loading.assignList">
      <div class="container--skeleton-tabs">
        <b-skeleton animation="wave" width="100%"></b-skeleton>
        <b-skeleton animation="wave" width="100%"></b-skeleton>
        <b-skeleton animation="wave" width="100%"></b-skeleton>
      </div>
      <b-skeleton-table
      :rows="pagination.limit || 10"
        :columns="12"
        :table-props="{ }"/>
    </div>
  </div>
</template>
<script>
import listTicketUnAssign from './listTicketUnAssign.vue'
import listTicketAssign from './listTicketAssign.vue'
import listTicketCompleted from './listTicketCompleted.vue'
export default {
  props: ['dataTabAssign', 'permission', 'countList', 'loading', 'activeTab'],
  components: {
    listTicketUnAssign,
    listTicketAssign,
    listTicketCompleted
  },
  data() {
    return {
      dataTab: {
        listAssign: [],
        listCompleted: [],
        listUnassign: [],
        listUsers: [],
        listUsersDropdown: []
      },
      dataTabSave: {
        listAssign: [],
        listCompleted: [],
        listUnassign: [],
        listUsers: [],
        listUsersDropdown: []
      },
      listCarriers: [],
      listCompanies: [],
      listCategories: [],
      pagination: {
        page: 1,
        total: 0,
        limit: 10,
        noShowDigits: false,
        calculateCountPage: true
      }
    }
  },
  computed: {
    indexStart() {
      return Math.ceil((this.pagination.page - 1) * this.pagination.limit)
    },
    indexEnd() {
      return Math.ceil(this.indexStart + this.pagination.limit)
    }
  },
  watch: {
    dataTabAssign: {
      handler(value) {
        this.pagination.page = 1
        if (value.listAssign && value.listAssign.length > 0) this.pagination.total = value.listAssign.length
        if (value.listCompleted && value.listCompleted.length > 0) this.pagination.total = value.listCompleted.length
        if (value.listUnassign && value.listUnassign.length > 0) this.pagination.total = value.listUnassign.length
        this.dataTabSave = this.setData(value)
        this.dataTab = {
          listAssign: [],
          listCompleted: [],
          listUnassign: [],
          listUsers: value.listUsers.filter((el) => el.active) ?? [],
          listUsersDropdown: value.listUsers ?? []
        }
        setTimeout(() => {
          this.changePagination(this.pagination.page)
        }, 1000)
      },
      deep: true
    }
  },
  methods: {
    setData(value) {
      return {
        listAssign: value.listAssign ?? [],
        listCompleted: value.listCompleted ?? [],
        listUnassign: value.listUnassign ?? [],
        listUsers: value.listUsers.filter((el) => el.active) ?? [],
        listUsersDropdown: value.listUsers ?? []
      }
    },
    setDataPagination() {
      switch (this.activeTab) {
      case 'NOT_ASSIGNMENT':
        this.dataTab.listUnassign = this.dataTabSave.listUnassign.slice(this.indexStart, this.indexEnd < this.pagination.limit ? this.pagination.limit : this.indexEnd) ?? []
        break
      case 'ASSIGNMENT':
        this.dataTab.listAssign = this.dataTabSave.listAssign.slice(this.indexStart, this.indexEnd < this.pagination.limit ? this.pagination.limit : this.indexEnd) ?? []
        break
      case 'COMPLETED':
        this.dataTab.listCompleted = this.dataTabSave.listCompleted.slice(this.indexStart, this.indexEnd < this.pagination.limit ? this.pagination.limit : this.indexEnd) ?? []
        break
      }
    },
    updateListTicket() {
      this.pagination.page = 1
      this.$emit('updateTicketAssign', true)
    },
    selectTab(tabName) {
      this.pagination.page = 1
      this.pagination.total = 0
      this.$emit('changeTab', tabName)
    },
    // updateListUser(dataListUser) {
    //   this.$emit('updateListUser', dataListUser)
    // },
    changePagination(value) {
      this.pagination.page = value
      this.setDataPagination()
    },
    changeLimit(value) {
      this.pagination.limit = value
      this.setDataPagination()
    }
  }
}
</script>
<style lang="scss" scoped>
  .count--assign .count--assign-badge {
    position: relative;
    top: -0.5rem;
    right: -0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .count--assign ul {
    gap: 2rem;
  }
</style>
<style lang="scss">
  .arrow--scroll--assign {
    width: 2rem;
    height: 2rem;
  }
  .container--skeleton-tabs {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(9,1fr);
  }
</style>