<template>
  <section>
    <b-breadcrumb >
      <b-breadcrumb-item @click="goToPlatform">
        <feather-icon
          icon="HomeIcon"
          size="16"
          class="align-text-top"
        />
      </b-breadcrumb-item>
      <b-breadcrumb-item>Gestión</b-breadcrumb-item>
      <b-breadcrumb-item active>{{ tabsItem === 0 ? 'Asignador' : 'Avanzado' }}</b-breadcrumb-item>
    </b-breadcrumb>
    <b-card class="tabs--assign--ticket">
      <b-tabs v-model="tabsItem" content-class="">
        <b-tab title="Asignado" active><list v-if="dataUser"></list></b-tab>
        <b-tab title="Avanzado" :disabled="!isLeader"><automatic-schedule v-if="isLeader && dataUser"></automatic-schedule></b-tab>
      </b-tabs>
    </b-card>
  </section>
</template>
<script>
import List from '@/views/modules/tickets-assign/List.vue'
import { environment } from '@/environments/environment'
import AutomaticSchedule from '@/views/modules/tickets-assign/AutomaticSchedule.vue'
import {
  BBreadcrumb,
  BBreadcrumbItem
} from 'bootstrap-vue'
export default {
  components: {List, AutomaticSchedule, BBreadcrumb, BBreadcrumbItem},
  data() {
    return {
      isLeader: false,
      tabsItem: 0
    }
  },
  computed: {
    dataUser() {
      return this.$session.get('cas_user')
    },
    permissionArray() {
      return this.$session.get('cas_user')?.special_permissions
    }
  },
  mounted() {
    this.firstLoad()
  },
  methods: {   
    firstLoad() {
      if (this.dataUser) {
        this.setDataPermissions()
      } else {
        setTimeout(() => {
          this.$router.go(this.$router.currentRoute)
        }, 1000)
      }
    },
    goToPlatform () {
      window.open(`${environment.platformUrl}`, '_parent')
    }, 
    setDataPermissions() {
      const viewPermissionsLeader = ['team-leader-assignments', 'team-leader-assign']
      this.isLeader = this.permissionArray ? !!this.permissionArray.find((element) => viewPermissionsLeader.includes(element)) : false
    }
  }
}
</script>
<style lang="scss">
  .tabs--assign--ticket {
    min-height: 90vh;
  }
</style>