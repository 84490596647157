<template>
  <div class="drop--down-assign">
    <b-dropdown v-b-tooltip.hover title="Asignar Usuario" size="lg" right dropup variant="link" toggle-class="text-decoration-none flex p-0" menu-class="drop--down-menu-list"  no-caret>
      <template #button-content>
        <div v-if="userAssign" class="drop--down--button">
          <div class="drop--down-item">
            <img v-if="userAssign.photo" :src="userAssign.photo" alt="foto" class="drop--down-photo mr-1 ">
            <feather-icon class="drop--down-photo" v-else icon="UserIcon"/>
          </div>
          <span class="drop--down-item">{{userAssign.name}}</span>
        </div>
         <icons v-else :icon="'userAssign'" :size="sizeIcon" class="" ></icons>
      </template>
      <div class="drop--down-user-list" :class="!userAssign ? 'unassign' : ''">
        <b-button  v-show="userAssign" variant="light"  class="drop--down--button drop--down--button-assign border-0" @click="changeStatus('unAssign')">
          Desasignar
        </b-button>
        <template v-for="(option, index) in listOptionDropdown">
          <div :key="`button-${index}`">
            <b-button variant="light"  class="drop--down--button border-0" @click="changeStatus(index)">
              <div class="drop--down-item">
                <img v-if="option.photo" :src="option.photo" alt="foto" class="drop--down-photo mr-1 ">
                <feather-icon class="drop--down-photo" v-else icon="UserIcon"/>
              </div>
              <span class="drop--down-item">{{option.text}}</span>
            </b-button>
          </div>

        </template>
      </div>
    </b-dropdown>
  </div>
</template>
<script>
import Icons from '@/Icons/Icons.vue'

export default {
  props: ['listOptions', 'indexTicket', 'userAssign'],
  data() {
    return {
      sizeIcon: {
        width: '20px',
        height: '20px'
      }
    }
  },
  computed: {
    listOptionDropdown() {
      return this.listOptions && this.listOptions.length > 0 ? [...this.listOptions] : []
    }
  },
  components: {Icons},
  methods: {
    changeStatus(index) {
      if (index !== 'unAssign') {
        const dataUser = this.listOptions[index]
        dataUser.indexTicket = this.indexTicket
        this.$emit('updateListUsers', dataUser)
      } else {
        const dataSend = {
          action: 'unAssign',
          indexTicket: this.indexTicket
        }
        this.$emit('updateListUsers', dataSend)
      }
    }
  }
}
</script>
<style lang="scss" >
.drop--down-assign {
  .drop--down-badge {
    position: relative;
    top: -1rem;
  }
  .drop--down-menu-list {
    background: linear-gradient(0deg, rgba(22, 81, 145, 0.11), rgba(22, 81, 145, 0.11)), #FFFBFE;
    border-radius: 0;
    margin: 0;
    min-width: 20rem;
    padding: .5rem;

    &-title {
      font-size: .5rem;
    }
    &-subtitle {
      font-size: 1.063rem;
    }
  }
  .drop--down-photo {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 2.5rem;
  }
  .drop--down-user-list {
    display: grid;
    grid-template-columns: auto;
    gap: 1.25rem 0;
    align-items: center;
    line-height: 2.6rem;
    // width: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .drop--down-user-list.unassign {
    height: 300px;
  }
  .drop--down-user-list::-webkit-scrollbar {
    display: none;
  }
  .drop--down-item {
    border-bottom: 0;
    padding-bottom: 0;
    text-align: left;
    text-transform: capitalize;
  }

  .drop--down--button {
    border-radius: 0;
    display: flex;
    padding: 0;
    align-items: center;
    gap: 1rem;
    color: black;
    background: transparent;
    width: 100%;
    font-size: 1rem;

    &-assign {
      height: 40px;
    }
  }
}
</style>