<template>
  <b-card v-show="!!permission.teamLeader || !!permission.teamOperator">
    <div v-show="!loading.first || !loading.filter">
      <filter-swapper class="mb-1" :controlHeightButtons="controlHeight">
        <template #slot1>
          <form-render :form.sync="form" :fields="fieldsForm" @send="filterAssign" >
            <template #buttonsGroupSlot>
              <label></label>
              <div class="assign--buttons">
                <div class="assign--buttons--container">
                  <b-button variant="warning" type="submit"><feather-icon icon="SearchIcon" /></b-button>
                  <b-button variant="outline-info" @click="resetForm" ><feather-icon icon="RefreshCwIcon" /></b-button>
                  <b-button v-show="permission.teamLeader" variant="link" class="assign--buttons--link" v-b-modal.modalAdvancedFilter>Búsqueda avanzada</b-button>
                  <drop-down-user-vue v-show="permission.teamLeader" :listOptions="dataUsersBo" @updateListUsers="setOnlyDataUser"></drop-down-user-vue>
                </div>
              </div>
            </template>
          </form-render>
        </template>
      </filter-swapper>
      <tabs-assign-list
        :countList="countList"
        :permission="permission"
        :dataTabAssign="dataTabAssign"
        :loading="loading"
        :activeTab="activeTab"
        ref="tabsAssignListRef"
        @updateTicketAssign="updateTicketAssign"
        @changeTab="changeTabFilter"
        @updateListUser="setOnlyDataUser"></tabs-assign-list>
    </div>
    <modal-advance-filter ref="advanceFilterRef" :dataModalAssign="dataModalAssign" @advancedSearch="filterAdvanced"></modal-advance-filter>
  </b-card>
</template>
<script>
import TabsAssignList from './components/tabsAssignList.vue'
import BaseServices from '@/store/services/index'
import ModalAdvanceFilter from './components/modalAdvancedFilter.vue'
import dropDownUserVue from './components/dropDownUser.vue'
export default {
  components: {
    TabsAssignList,
    ModalAdvanceFilter,
    dropDownUserVue
  },
  data() {
    return {
      baseService: new BaseServices(this),
      controlHeight: { class: 'row mb-12 spacing-label-field'},
      form: {},
      dataCategory: [],
      dataCarrier: [],
      dataPriorityLevels: [],
      dataUsersBo: [],
      fieldsForm: [],
      dataListUnAssign: [],
      dataListAssign: [],
      dataListAssignCompleted: [],
      dataTabAssign: {},
      dataModalAssign: {},
      countries: this.$i18nImport('es').es,
      countryList: [],
      loading:{
        first: true,
        filter: false,
        assignList: false
      },
      permission: {
        teamLeader: false,
        teamOperator: false,
        team: 'team_bo_all'
      },
      countList: {
        notAssign: 0,
        assign: 0,
        completed: 0
      },
      activeTab: 'NOT_ASSIGNMENT'
    }
  },
  mounted() {
    this.firstLoad()
  },
  computed: {
    dataUser() {
      return this.$session.get('cas_user')
    },
    permissionArray() {
      return this.$session.get('cas_user')?.special_permissions
    }
  },
  methods: {
    firstLoad() {
      if (this.dataUser) {
        setTimeout(() => {
          this.setDataPermissions()
        }, 100)
      } else {
        setTimeout(() => {
          this.$router.go(this.$router.currentRoute)
        }, 1000)
      }
    },
    changeTabFilter(data) {
      this.activeTab = data
      if (!this.loading.first) {
        this.filterAssign()
      }
    },
    updateTicketAssign() {
      setTimeout(() => {
        this.filterAssign()
      }, 1000)
    },
    filterAdvanced(data) {
      this.form = {...this.form, ...data}
      this.filterAssign()
    },
    filterAssign() {
      this.loading.filter = true
      this.loading.assignList = true
      const params = {
        shipper_id: this.$session.get('cas_user').id ? this.$session.get('cas_user').id : ''
      }
      const queryParams = {}
      queryParams.role = this.permission.teamLeader ? 'leader' : 'operator'
      queryParams.team = this.permission.team ?? 0

      if (this.form.category) queryParams.category_id = this.form.category.id
      if (this.form.priority) queryParams.priority_level_id = this.form.priority.id
      if (this.form.userAssign) queryParams.user_assignment = this.form.userAssign.email
      if (this.form.country) queryParams.country_code = this.form.country.code
      if (this.form.carrier) queryParams.carrier_code = this.form.carrier.code
      if (this.form.company) queryParams.company_id = this.form.company.id
      if (this.form.idTicket) queryParams.delivery_id = this.form.idTicket
      if (this.form.contractType) queryParams.contract_type_code = this.form.contractType.id
      if (this.activeTab) queryParams.status_assignment = this.activeTab

      if (!this.permission.teamLeader && this.$session.get('cas_user').email) queryParams.user_assignment = this.$session.get('cas_user').email
      const arrServices = [
        {
          name: 'getUsersBo',
          params: {
            ...params
          }
        },
        {
          name: 'getAssignments',
          params: {
            ...params
          },
          queryParams: {
            ...queryParams
          }
        }
      ]
      this.baseService.callMultipleServices(arrServices, true)
        .then(response => {
          this.setDataAssign(response.getAssignments.data)
          this.setDataCounts(response.getAssignments.counts)
          this.setDataUserBo(response.getUsersBo.data)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.loading.assignList = false
          this.loading.filter = false
          this.setDataTab()
          this.$bvModal.hide('modalAdvancedFilter')
        })
    },
    getAllData() {
      this.loading.first = true
      this.loading.assignList = true
      const params = {
        shipper_id: this.$session.get('cas_user')?.id ? this.$session.get('cas_user').id : ''
      }
      const queryParams = {}
      queryParams.role = this.permission.teamLeader ? 'leader' : 'operator'
      queryParams.team = this.permission.team ?? 0
      this.activeTab = this.permission.teamLeader ? 'NOT_ASSIGNMENT' : 'ASSIGNMENT'
      queryParams.status_assignment = this.activeTab
      if (!this.permission.teamLeader && this.$session.get('cas_user')?.email) {
        queryParams.user_assignment = this.$session.get('cas_user').email
      } 
      const arrServices = [
        {
          name: 'getPriorityLevels',
          params: {
            ...params
          }
        },
        {
          name: 'getUsersBo',
          params: {
            ...params
          }
        },
        {
          name: 'getAssignments',
          params: {
            ...params
          },
          queryParams: {
            ...queryParams
          }
        },
        {
          name: 'getCategoriesBo',
          params: {
            ...params
          }
        },
        {
          name: 'getCountries',
          params: {
            ...params
          }
        }
      ]
      this.baseService.callMultipleServices(arrServices, true)
        .then(response => {
          this.setDataCategory(response.getCategoriesBo.data)
          this.setDataPriorityLevels(response.getPriorityLevels.data)
          this.setDataUserBo(response.getUsersBo.data)
          this.setDataAssign(response.getAssignments.data)
          this.setDataCounts(response.getAssignments.counts)
          this.countryList = response.getCountries.data.map(el => ({
            ...el,
            text: el.name ?? ''
          }))
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.loading.first = false
          this.loading.assignList = false
          this.setDataForm()
        })
    },
    setDataPermissions() {
      const viewPermissionsLeader = ['team-leader-assignments', 'team-leader-assign']
      const viewPermissionsOperator = ['team-operator-assignments']
      const isLeader = this.permissionArray ? !!this.permissionArray.find((element) => viewPermissionsLeader.includes(element)) : false
      const isOperator = this.permissionArray && !isLeader ? !!this.permissionArray.find((element) => viewPermissionsOperator.includes(element)) : false
      this.permission = {
        teamLeader: isLeader,
        teamOperator: isOperator,
        team: this.setDataTeam()
      }
      this.getAllData()
    },
    setDataTeam() {
      const viewPermissionTeam = ['team_bo_all', 'team_tickets', 'team_compensation']
      const haveTeam = this.permissionArray?.find((element) => viewPermissionTeam.includes(element)) ?? 'team_bo_all'
      return haveTeam
    },
    resetForm() {
      this.form = {}
      this.$refs.advanceFilterRef.cleanForm()
      this.filterAssign()
    },
    setDataForm() {
      if (this.permission.teamLeader) {
        this.fieldsForm = [
          { fieldType: 'FieldSelect', name: 'category', label: 'Categoría', containerClass: 'col-sm-12 container-info col-md-3', options: this.dataCategory, searchOnType: { nChars: 999} },
          { fieldType: 'FieldSelect', name: 'priority', label: 'Niveles de prioridad', containerClass: 'col-sm-12 container-info col-md-2', options: this.dataPriorityLevels, searchOnType: {nChars: 999} },
          { fieldType: 'FieldSelect', name: 'userAssign', label: 'Usuario Asignado', containerClass: 'col-sm-12 container-info col-md-3', options: this.dataUsersBo },
          {name: 'buttonsGroupSlot', useSlot:true, containerClass: 'col-12 col-md-4'}
        ]
      } else {
        this.fieldsForm = [
          { fieldType: 'FieldInput', name: 'idTicket', label: 'ID Ticket', containerClass: 'col-sm-12 container-info col-md-3' },
          { fieldType: 'FieldSelect', name: 'priority', label: 'Niveles de prioridad', containerClass: 'col-sm-12 container-info col-md-3', options: this.dataPriorityLevels, searchOnType: {nChars: 999} },
          { fieldType: 'FieldSelect', name: 'company', label: 'Empresa', containerClass: 'col-sm-12 container-info col-md-3', searchOnType: { fx: this.searchShippers, nChars: 3, debounce: 600, allowSearchAsValue: true, persistSearch: true } },
          { name: 'buttonsGroupSlot', useSlot:true, containerClass: 'col-12 col-md-3' }
        ]
      }
      this.setDataModal()
    },
    setDataTab() {
      this.dataTabAssign = {
        listUsers: this.dataUsersBo ?? [],
        listUnassign: this.dataListUnAssign ?? [],
        listAssign: this.dataListAssign ?? [],
        listCompleted: this.dataListAssignCompleted ?? []
      }
    },
    setDataModal() {
      this.dataModalAssign = {
        listCategory: this.dataCategory ?? [],
        listPriority: this.dataPriorityLevels ?? [],
        listUsers: this.dataUsersBo ?? [],
        listCountries: this.countryList ?? []
      }
    },
    setDataCategory(data) {
      this.dataCategory = data.map(el => ({
        ...el,
        text: el.name
      }))
    },
    setDataCarrier(data) {
      this.dataCarrier = [...data]
    },
    setDataPriorityLevels(data) {
      this.dataPriorityLevels = data.map(el => ({
        ...el,
        text: el.label
      }))
    },
    setDataUserBo(data) {
      this.dataUsersBo = data.map(el => ({
        ...el,
        text: el.name ? `${el.name} ${el.last_name}` : '',
        active: el.active ?? false,
        value: el.id,
        photo: el.photo ?? ''
      }))
    },
    setOnlyDataUser (dataUser) {
      this.setDataUserBo(dataUser)
      this.dataTabAssign.listUsers = this.dataUsersBo
      this.dataModalAssign.listUsers = this.dataUsersBo
    },
    setDataAssign(data) {
      this.dataListUnAssign = []
      this.dataListAssign = []
      this.dataListAssignCompleted = []
      if (data && data.length > 0) {
        switch (this.activeTab) {
        case 'NOT_ASSIGNMENT':
          this.dataListUnAssign = [...this.dataListUnAssign, ...data]
          break
        case 'ASSIGNMENT':
          this.dataListAssign = [...this.dataListAssign, ...data]
          break
        case 'COMPLETED':
          this.dataListAssignCompleted = [...this.dataListAssignCompleted, ...data]
          break
        }
      }
      this.setDataTab()
    },
    setDataCounts(data) {
      this.countList = {
        notAssign: data.count_not_assignment ?? 0,
        assign: data.count_assignment ?? 0,
        completed: data.count_completed ?? 0
      }
    },
    formatDate(date) {
      return date ? this.$options.filters.dbDateToFormat(date, 'dd-LL-y T') : ''
    },
    setCountry(country) {
      return this.countries[country]
    },
    searchShippers(value) {
      const params = {
        shipper_id: this.$session.get('cas_user').id ? this.$session.get('cas_user').id : ''
      }
      const queryParams = {
        name_sub_string: value
      }
      return this.baseService.callService('getShippersSearch', queryParams, params)
        .then(response => {
          const shipper = response.data.map(el => ({
            ...el,
            text: el.name1
          }))
          return shipper
        })
        .catch(err => {
          console.error(err)
        })
    }
  }
}
</script>
<style lang="scss" scope>
  .assign--buttons {
    display: grid;
    grid-auto-rows: 19.2px auto;

    &--container {
      display: flex;
      gap: 10px;
      height: 38px;
    }

    &--link {
      padding: 0;
      text-decoration: underline;
    }
  }

</style>