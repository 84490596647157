<template>
  <section class="automatic--schedule--assign">
    <p>
      Busca un usuario y personaliza sus configuraciones de trabajo.
      Cada usuario puede tener parámetros únicos que puedes editar, agregar o eliminar según tus necesidades.
      Repite el proceso para cada usuario que requiera ajustes.
    </p>
    <form-render :form.sync="form" :fields="fields" :key="keyFormRender" @send="filterUsers">
      <template #buttonsGroupSlot>
        <div>
          <b-button variant="warning" type="submit"><feather-icon icon="SearchIcon" /></b-button>
          <b-button variant="outline-primary" @click="resetForm" class="ml-1"><feather-icon icon="RefreshCwIcon" /></b-button>
        </div>
      </template>
    </form-render>
    <b-list-group class="list-group--container">
      <b-list-group-item @click="showUser(user)" v-for="(user, index) of rowUsers" :key="index"
        button class="list-group--item">
        <div>
          <img v-if="user.photo" :src="user.photo" alt="foto" class="drop--down-photo">
          <feather-icon class="drop--down-photo" v-else icon="UserIcon"/>
        </div>
        <p class="m-0">
          {{user.name}} {{user.last_name}}
        </p>
        <feather-icon class="chevron--icon" icon="ChevronRightIcon"/>
      </b-list-group-item>
    </b-list-group>
    <config-user 
      :selectUser="selectUser"
      :countryList="countryList"
      :viewDataUser="viewDataUser"
      @hideSidebar="updateUsers"/>
  </section>
</template>
<script>
import BaseServices from '@/store/services/index'
import configUser from '@/views/modules/tickets-assign/componentsSchedule/configUser.vue'
export default {
  components: {configUser},
  data() {
    return {
      form: {},
      fields: [],
      keyFormRender: 0,
      baseService: new BaseServices(this),
      countryList: [],
      listUsers: [],
      filterUsersList: [],
      listTeams: [
        { id: 1, text: 'Incidencias'},
        { id: 2, text: 'Indemnizaciones'}
      ],
      viewDataUser: false,
      selectUser: {}
    }
  },
  computed: {
    rowUsers() {
      return this.filterUsersList.length > 0 ? this.filterUsersList : []
    }
  },
  methods: {
    resetForm(e) {
      e.preventDefault()
      this.form = {}
      this.filterUsersList = []
      this.keyFormRender++
    },
    getAllData() {
      const arrServices = [
        {
          name: 'getUsersBoConfig',
          queryParams: {
            active: 1
          }
        },
        {
          name: 'getCountries'
        }
      ]
      this.baseService.callMultipleServices(arrServices, true)
        .then(response => {
          this.setDataUserBo(response.getUsersBoConfig.data)
          this.setDataCountry(response.getCountries.data)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.setFormData()
        })
    },
    filterUsers() {
      const queryParams = {
        country: this.form.country?.code ?? '',
        team: this.form.team?.id ?? '',
        user_id: this.form.user_id?.id ?? '',
        active: 1
      }
      Object.entries(queryParams).forEach(([key, value]) => {
        if (value === '') {
          delete queryParams[key]
        }
      })
      this.baseService.callService('getUsersBoConfig', queryParams, {})
        .then(response => {
          if (response && response.data) {
            this.filterUsersList = response.data
          } else {
            this.filterUsersList = []
            this.$alert('La búsqueda no ha arrojado usuarios.')
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    updateUsers(command) {
      this.viewDataUser = false
      document.body.style.overflow = 'auto'
      if (command) {
        this.filterUsers()
      }
    },
    setDataUserBo(data) {
      this.listUsers = data.map(el => ({
        ...el,
        text: `${el.name} ${el.last_name}` ?? '',
        active: el.active ?? false,
        value: el.id,
        photo: el.photo ?? ''
      }))
    },
    setDataCountry(data) {
      this.countryList = data.map(el => ({
        ...el,
        text: el.name ?? ''
      }))
    },
    setFormData() {
      this.fields = [
        { fieldType: 'FieldSelect', name: 'country', label: 'País', containerClass: 'col-sm-12 container-info col-md-3', options: this.countryList, addFlags: true },
        { fieldType: 'FieldSelect', name: 'team', label: 'Equipo de trabajo', containerClass: 'col-sm-12 container-info col-md-3', options: this.listTeams, searchOnType: {nChars: 999} },
        { fieldType: 'FieldSelect', name: 'user_id', label: 'Usuario', containerClass: 'col-sm-12 container-info col-md-3', options: this.listUsers, searchOnType: {nChars: 999} },
        {name: 'buttonsGroupSlot', label: 'Acciones', useSlot:true, containerClass: 'col-sm-12 col-md-3', labelClass: 'text-white'}
      ]
      this.keyFormRender++
    },
    showUser(user) {
      this.selectUser = {...user}
      this.viewDataUser = true
    } 
  },
  mounted() {
    this.getAllData()
  }
}
</script>
<style lang="scss">
  .automatic--schedule--assign {
    .list-group--container {
      border-radius: 0;
    }
    .list-group--item {
      border-left: 0;
      border-right: 0;
      display: grid;
      grid-template-columns: auto 1fr auto;
      gap: 10px;
      align-items: center;
    }
    .drop--down-photo {
      width: 40px;
      height: 40px;
      border-radius: 40px;
    }
    .chevron--icon {
      color: #B9B9C7;
    }
    .header--sidebar {
      border-top: 1px solid #E3E3E3;
      border-bottom: 1px solid #E3E3E3;
      gap: 10px;
    }
  }
</style>