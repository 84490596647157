<template >
  <section class="config--user--assign">
    <b-sidebar title="Sidebar" right shadow :visible="viewDataUser" width="100vw" bg-variant="white" 
      header-class="d-flex align-items-center header--sidebar" body-class="p-2" @shown="onReset">
      <template #header>
        <b-button @click="() => $emit('hideSidebar', '')" variant="link" class="p-0">
          <feather-icon size="1.5x" class="chevron--icon" icon="ChevronLeftIcon"/>
        </b-button>
        <div>
          <img v-if="selectUser.photo" :src="selectUser.photo" alt="foto" class="drop--down-photo">
          <feather-icon class="drop--down-photo" v-else icon="UserIcon"/>
        </div>
        <p class="m-0">
          {{selectUser.name}} {{selectUser.last_name}}
        </p>
      </template>
      <div class="d-flex align-items-center" v-if="!isEditing">
        <b-button variant="outline-danger" @click="removeConfig" :disabled="selected.length === 0 || loading.delete" >
          <feather-icon v-if="!loading.delete" icon="Trash2Icon"/>
          <b-icon v-else icon="arrow-clockwise" animation="spin"></b-icon>
          Eliminar
        </b-button>
        <p class="m-0 ml-1">{{selected.length}} Configuraciones seleccionadas</p>
      </div>
      <b-card class="m-0" body-class="pb-0">
        <div class="body--class-list">
          <b-list-group id="groupListId">
            <div v-for="(config, index) in form" :key="index">
              <b-list-group-item class="border-0 list--group--container" :class="isEditing ? 'list--group--container-edit' : ''"  v-if="!!config">
                <b-form-checkbox
                  v-model="selected"
                  name="status"
                  :value="index"
                  v-if="!isEditing"
                  :disabled="loading.delete"
                />
                <div class="form--container" :class="isEditing ? 'form--container-edit' : ''">
                  <form-render :form.sync="form[index]" :fields="fieldsForm" :disableAll="!isEditing || loading.save" :key="keyFormRender"/>
                </div>
                <button variant="link" class="p-0 button--remove-condition" v-if="isEditing" :disabled="loading.save"      @click="removeParcialCondition(config.id)">
                  <feather-icon
                    icon="XIcon"
                    size="18"
                    title="Eliminar configuración"
                  />
                </button>
              </b-list-group-item>
            </div>
          </b-list-group>
        </div>
        <div class="d-flex justify-content-end" v-if="!isEditing">
          <b-button variant="warning" :disabled="loading.delete" @click="() => isEditing = true">Editar</b-button>
        </div>
        <div v-else  class="d-flex justify-content-between">
          <b-button variant="outline-warning" :pressed="false" class="button--default" :disabled="loading.save" @click="addConfig">Agregar Configuración</b-button>
          <div>
            <b-button variant="outline-danger" :disabled="loading.save" @click="onReset">Cancelar</b-button>
            <b-button variant="warning" class="ml-1 button--default" :disabled="loading.save" @click="onSubmit">Guardar</b-button>
          </div>
        </div>
      </b-card>
    </b-sidebar>
  </section>
</template>
<script>
import {
  BIcon,
  BSidebar,
  BIconArrowClockwise
} from 'bootstrap-vue'
/* eslint-disable vue/no-unused-components */
import BaseServices from '@/store/services/index'
export default {
  props: ['selectUser', 'countryList', 'viewDataUser'],
  components: {BSidebar, BIcon, BIconArrowClockwise},
  data() {
    return {
      baseService: new BaseServices(this),
      days: [
        {id: 1, text: 'Lunes', code: 'monday'},
        {id: 2, text: 'Martes', code: 'tuesday'},
        {id: 3, text: 'Miercoles', code: 'wendnesday'},
        {id: 4, text: 'Jueves', code: 'thursday'},
        {id: 5, text: 'Viernes', code: 'friday'},
        {id: 6, text: 'Sabado', code: 'saturday'},
        {id: 7, text: 'Domingo', code: 'sunday'}
      ],
      form: [],
      selected: [],
      isEditing: false,
      flagIndex: 0,
      fieldsForm: [],
      keyFormRender: 0,
      loading: {
        delete: false,
        save: false
      }
    }
  },
  methods: {
    addConfig() {
      this.flagIndex++
      this.form = [...this.form, {
        country: null,
        am: 0,
        pm: 0,
        day: null,
        id: this.flagIndex
      }]
      setTimeout(() => {
        const container = document.querySelector('#groupListId')
      }, 1000)
    },
    onSubmit(e) {
      e.preventDefault()
      this.loading.save = true
      const sendData = this.form
        .filter((el) => el.country !== null && el.day !== null)
        .map(({ status, country, day, am, pm, ...rest }) => ({
          ...rest,
          am: parseInt(am),
          pm: parseInt(pm),
          country: country.code,
          day: day.id
        }))
      this.saveData(sendData)
    },
    onReset() {
      this.form = []
      this.isEditing = false
      this.flagIndex = 0
      this.selected = []
      this.loading = {
        delete: false,
        save: false
      }
      this.showSidebar()
    },
    removeConfig() {
      this.loading.delete = true
      const sendData = this.form
        .filter((el, index) => !this.selected.includes(index))
        .map(({ status, country, day, ...rest }) => ({
          ...rest,
          country: country.code,
          day: day.id
        }))
      this.saveData(sendData)
    },
    removeParcialCondition(id) {
      this.form = this.form.filter(el => el.id !== id)
      this.keyFormRender++
    },
    saveData(data) {
      const queryParams = {
        data,
        user_id: this.selectUser.id
      }
      return this.baseService.callService('postUsersBoConfig', queryParams, {})
        .then(response => {
          this.$success('Configuración subida correctamente')
          this.$emit('hideSidebar', 'filterUsers')
        })
        .catch(err => {
          console.error(err)
          this.$alert('Algo fallo al guardar la configuración')
          this.onReset()
        })
    },
    setConfiguration() {
      return this.selectUser?.quantity_assignments ? this.selectUser?.quantity_assignments.map((el, index) => ({
        ...el,
        country: this.countryList.filter(element => element.code === el.country)[0],
        day: this.days.filter(element => element.id === el.day)[0],
        status: null,
        id: index
      })) : []
    },
    showSidebar() {
      document.body.style.overflow = 'hidden'
      this.form = this.setConfiguration()
      this.flagIndex = this.form.length
      this.fieldsForm = [
        { fieldType: 'FieldSelect', name: 'country', label: 'País', containerClass: 'col-sm-12 container-info col-md-3', options: this.countryList, searchOnType: {nChars: 999}, addFlags: true, validation: 'required'},
        {fieldType: 'FieldSelect', name: 'day', label: 'Días', containerClass: 'col-sm-12 container-info col-md-3', options: this.days, searchOnType: {nChars: 999},  validation: 'required'},
        {fieldType: 'FieldInput', name: 'am', label: 'Tickets asignados AM',  containerClass: 'col-sm-12 container-info col-md-3', type: 'number', append: {text:  'AM'}},
        {fieldType: 'FieldInput', name: 'pm', label: 'Tickets asignados PM',  containerClass: 'col-sm-12 container-info col-md-3', type: 'number', append: {text:  'PM'}}
      ]
      this.keyFormRender++
    }
  } 
}
</script>
<style lang="scss">
  .config--user--assign {
    .form--container {
      border: 0.5px solid #E3E3E3;
      background: #F8F8F8;
      padding: 20px;
    }
    .form--container-edit {
      background: #F0F7FA;
    }
    .list--group--container {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 10px;
      align-items: center;
    }
    .list--group--container-edit {
      grid-template-columns: 1fr 20px;
    }
    .body--class-list {
      max-height: 75vh;
      overflow-y: scroll;
      padding: 0;
      margin-bottom: 10px;
    }
    .button--remove-condition {
      background: none;
      border: 0;
    }
    .disabled {
      cursor: not-allowed;
    }
    .btn-outline-warning:not(:disabled):not(.disabled):focus {
      background: white;
    }
  }
</style>