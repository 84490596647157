<template>
  <div class="text-right drop--down--user">
    <b-dropdown title="Listado Usuarios" size="lg" right  variant="link" toggle-class="text-decoration-none flex" menu-class="drop--down-menu-list" no-caret>
      <template #button-content>
         <icons :icon="'userAssign'" :size="sizeIcon"></icons><b-badge pill variant="dark" class="drop--down-badge">{{listOptions.length}}</b-badge>
      </template>
      <h1 class="drop--down-menu-list-title">Lista de Usuarios</h1>
      <!-- <h2 class="drop--down-menu-list-subtitle mb-3">Administrar Usuarios</h2> -->
      <div v-for="(option, index) in listOptions" :key="index">
        <div class="drop--down-user-list">
          <div class="drop--down--user-left ">
            <div class="drop--down-item">
              <img v-if="option.photo" :src="option.photo" alt="foto" class="drop--down-photo mr-1 ">
              <feather-icon class="drop--down-photo" v-else icon="UserIcon"/>
            </div>
            <p class="drop--down-item">{{option.text}}</p>
            <p  class="drop--down-item">{{option.active ? 'Activo' : 'Inactivo'}}</p>
            <div class="select--container">
              <b-form-group class="select--form-group">
                <b-form-checkbox
                  v-model="option.active"
                  :name="`check-button-${index}`"
                  class="drop--down-item"
                  @input="changeStatus(option)"
                >
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div class="drop--down--user-right">
            <p>Total de tickets del corte diario</p>
            <b-badge pill variant="info">{{ option.count_tickets }}</b-badge>
          </div>
        </div>
        <div class="drop--down--line">

        </div>
      </div>
    </b-dropdown>
  </div>
</template>
<script>
import Icons from '@/Icons/Icons.vue'
import BaseServices from '@/store/services/index'

export default {
  props: ['listOptions'],
  data() {
    return {
      listOptionDropdown: [],
      baseService: new BaseServices(this),
      sizeIcon: {
        width: '30px',
        height: '30px'
      }
    }
  },
  components: {Icons},
  methods: {
    changeStatus(dataUser) {
      const idUser = dataUser.id
      const userExecuter = this.$session.get('cas_user').email
      const params = {
        idUser
      }
      const queryParams = {
        userExecuter
      }
      this.baseService.callService('setStatusUser', queryParams, params)
        .then(response => {
          this.$emit('updateListUsers',  response?.data?.data_updated ?? [])
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }
}
</script>
<style lang="scss" >
  .drop--down--user {
    p {
      margin: 0;
    }
    .drop--down-badge {
      position: relative;
      top: -1rem;
    }
    .drop--down-menu-list {
      background: linear-gradient(0deg, rgba(22, 81, 145, 0.11), rgba(22, 81, 145, 0.11)), #FFFBFE;
      border-radius: 28px;
      margin: 0;
      min-width: 50rem;
      padding: 1.5rem;

      &-title {
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        padding-bottom: 20px;
        border-bottom: 1px solid #79747E;
        margin: 0 0 15px 0;
      }
      &-subtitle {
        font-size: 1.063rem;
      }
    }
    .drop--down-photo {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 2.5rem;
    }
    .drop--down-user-list {
      display: grid;
      grid-template-columns: 1.2fr .8fr;
      gap: 20px;
      align-items: center;
      line-height: 2.6rem;
      row-gap: 5px;
    }
    .drop--down--user-left {
      display: grid;
      grid-template-columns: 2.5rem auto 4.2rem 20px;
      align-items: center;
    }
    .drop--down--user-right {
      display: grid;
      grid-template-columns: 1fr auto;

      .pill-right {
        align-content: center;
      }
    }
    .drop--down-item {
      text-transform: capitalize;
      padding: 0;
    }
    .drop--down-item .custom-radio.custom-control {
      padding: 0;
    }
    .drop--down--line {
      border-bottom: 1px solid #79747E;
      height: 15px;
      margin-bottom: 15px;
    }
    .select--container {
      display: grid;
    }
    .select--form-group {
      width: 18px;
      height: 18px;
    }
  }
</style>