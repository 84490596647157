<template>
  <b-modal id="modalAdvancedFilter" :title="'Búsqueda avanzada'" size="lg" centered text class="" ok-only ok-title="Buscar" ok-variant="warning" hide-footer>
    <b-card>
      <form-render :form.sync="form" :fields="fieldsForm" @send="filterAdvanced" >
        <template #buttonsGroupSlot>
          <div>
            <b-button variant="warning" type="submit">Buscar</b-button>
          </div>
        </template>
      </form-render>
    </b-card>
  </b-modal>
</template>
<script>
import BaseServices from '@/store/services/index'
export default {
  props: ['dataModalAssign'],
  data() {
    return {
      form: {},
      fieldsForm: [],
      dataOptions: {
        listCategory: [],
        listPriority: [],
        listUsers: [],
        listCountries: [],
        listContract: [
          {
            id: 'DIS',
            text: 'Distribución'
          },
          {
            id: 'PAS',
            text: 'Pasarela'
          }
        ]
      },
      baseService: new BaseServices(this)
    }
  },
  watch: {
    dataModalAssign: {
      handler(value) {
        this.setDataOptions(value)
      },
      deep: true
    }
  },
  methods: {
    setData() {
      this.fieldsForm = [
        { fieldType: 'FieldSelect', name: 'category', label: 'Categoría', containerClass: 'col-sm-12 container-info', options: this.dataOptions.listCategory },
        { fieldType: 'FieldSelect', name: 'carrier', label: 'Courier', containerClass: 'col-sm-12 container-info',  searchOnType: { fx: this.searchCarriers, nChars: 3, debounce: 600, allowSearchAsValue: true, persistSearch: true } },
        { fieldType: 'FieldSelect', name: 'priority', label: 'Niveles de prioridad', containerClass: 'col-sm-12 container-info', options: this.dataOptions.listPriority},
        { fieldType: 'FieldSelect', name: 'userAssign', label: 'Usuario Asignado', containerClass: 'col-sm-12 container-info', options: this.dataOptions.listUsers },
        { fieldType: 'FieldInput', name: 'idTicket', label: 'ID Ticket', containerClass: 'col-sm-12 container-info' },
        { fieldType: 'FieldSelect', name: 'country', label: 'País', containerClass: 'col-sm-12 container-info', options: this.dataOptions.listCountries, addFlags: true },
        { fieldType: 'FieldSelect', name: 'company', label: 'Empresa', containerClass: 'col-sm-12 container-info', searchOnType: { fx: this.searchShippers, nChars: 3, debounce: 600, allowSearchAsValue: true, persistSearch: true } },
        { fieldType: 'FieldSelect', name: 'contractType', label: 'Tipo de contrato', containerClass: 'col-sm-12 container-info', options: this.dataOptions.listContract},
        {name: 'buttonsGroupSlot', useSlot:true, containerClass: 'col-12'}
      ]
    },
    setDataOptions(data) {
      this.dataOptions = {
        ...this.dataOptions,
        listCategory: data.listCategory,
        listPriority: data.listPriority,
        listUsers: data.listUsers,
        listCountries: data.listCountries
      }
      this.setData()
    },
    filterAdvanced() {
      this.$emit('advancedSearch', this.form)
    },
    searchCarriers(value) {
      const params = {
        shipper_id: this.$session.get('cas_user').id ? this.$session.get('cas_user').id : ''
      }
      const queryParams = {
        name_sub_string: value
      }
      return this.baseService.callService('getCarrierSearch', queryParams, params)
        .then(response => {
          const carrier = response.data ? response.data.map(el => ({
            ...el,
            text: el.name
          })) : []
          return carrier
        })
        .catch(err => {
          console.error(err)
        })
    },
    searchShippers(value) {
      const params = {
        shipper_id: this.$session.get('cas_user').id ? this.$session.get('cas_user').id : ''
      }
      const queryParams = {
        name_sub_string: value
      }
      return this.baseService.callService('getShippersSearch', queryParams, params)
        .then(response => {
          const shipper = response.data ? response.data.map(el => ({
            ...el,
            text: el.name1
          })) : []
          return shipper
        })
        .catch(err => {
          console.error(err)
        })
    },
    cleanForm() {
      this.form = {}
    }
  }
}
</script>