<template>
  <b-modal id="modalMassiveAssign"
    size="sm" centered text class="" ok-only ok-title="Asignar" ok-variant="success"
    :ok-disabled="selected.length === 0"
    :title="'Asignación Masiva'" @hidden="hideModal" @ok="handleOk">
    <!-- <b-card> -->
      <!-- Soy una modal
      {{ massiveAssign }} -->
      <b-form-group>
        <template #label>
          Selecciona los usuarios a los que serán asignados los {{ massiveAssign.listTickets ? massiveAssign.listTickets.length : 0 }} tickets de forma masiva:
          <br>
          <br>
          <b-form-checkbox
            v-model="selectAll"
            :indeterminate="indeterminate"
            aria-describedby="massiveAssign.listUsers"
            aria-controls="massiveAssign.listUsers"
            @change="toggleAll"
            switch >
            {{ selectAll ? 'Deseleccionar' : 'Seleccionar todos' }}
          </b-form-checkbox>
        </template>
        <template v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
          v-model="selected"
          :aria-describedby="ariaDescribedby"
          stacked switches>
          <b-form-checkbox v-for="option in massiveAssign.listUsers" :key="option.id" :value="option.id">
            <div class="drop--down-item">
              <img v-if="option.photo" :src="option.photo" alt="foto" class="drop--down-photo mr-1 ">
              <feather-icon class="drop--down-photo" v-else icon="UserIcon"/>
              {{ option.name }} {{ option.last_name }}
            </div>
          </b-form-checkbox>
        
          </b-form-checkbox-group>
        </template>
    </b-form-group>
    <!-- </b-card> -->
  </b-modal>
</template>
<script>
import {
  BFormGroup} from 'bootstrap-vue'
export default {
  props: ['massiveAssign'],
  components: {
    BFormGroup
  },
  data() {
    return {
      selected: [],
      textCheckAll: 'Seleccionar todos',
      selectAll: false,
      indeterminate: false
    }
  },
  watch: {
    selected(newValue, oldValue) {
      if (newValue.length === 0) {
        this.indeterminate = false
        this.selectAll = false
      } else if (newValue.length === this.massiveAssign.listUsers.length) {
        this.indeterminate = false
        this.selectAll = true
      } else {
        this.indeterminate = true
        this.selectAll = false
      }
    }
  },
  methods: {
    toggleAll(checked) {
      this.selected = checked ? this.massiveAssign.listUsers.map(el => el.id) : []
    },
    hideModal() {
      this.selected = []
      this.$emit('resetSelect', true)
    },
    handleOk() {
      const queryParams = {
        bo_user_id: [...this.selected],
        tickets_assignment_id: [...this.massiveAssign.listTickets]
      }
      this.$emit('setUsersService', queryParams)
    }
  }
}
</script>
<style lang="scss">
  .drop--down-photo {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 2.5rem;
  }
  .drop--down-item {
    padding-bottom: 0.6rem;
  }
</style>